<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
@import "./assets/css/common.css";

#app {
  font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-width: 1260px;
  overflow-x: hidden;
  overflow: auto;
}
</style>
