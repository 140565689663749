import svgIcon from './svgIcon.vue';
import wlTitle from './wlTitle.vue'
import submitPage from './submitPage.vue'
import breadcrumb from './breadcrumb.vue';
import wlBanner from './wlBanner.vue';

export default function registerComponents(Vue){
    Vue.component('svg-icon', svgIcon);
    Vue.component('wl-title', wlTitle);
    Vue.component('submit-page', submitPage);
    Vue.component('breadcrumb', breadcrumb);
    Vue.component('wl-banner', wlBanner);
}