<template>
    <section class="banner" :style="{'background': 'url(' + bgUrl + ') center/cover'}">
        <section class="container">
            <section class="left" :style="{'padding-top': paddingTop}">
                <h1 :style="{'padding-bottom': titlePb}">{{bannerData.title}}</h1>
                <strong :style="{'line-height': descLh}">{{bannerData.desc}}</strong>
            </section>
        </section>
    </section>
</template>

<script>
export default {
  name: 'wlBanner',
  props: {
    bannerData: {
        type: Object,
        default: () => ({title:'',desc:''})
    },
    paddingTop:{
        type: String,
    },
    titlePb: {
        type: String,
    },
    descLh: {
        type: String,
        default: ''
    },
    bgUrl: {
        type: String,
        required: true
    },
},
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
.banner{
  height: 540px;
  .left{
    width: 600px;
    padding-top: 199px;
    text-align: left;
    h1{
      font-size: 40px;
      line-height: 40px;
      padding-bottom: 34px;
    }
    strong{
      font-size: 24px;
      color: #6D6D6D;
      line-height: 46px;
      white-space: pre;
    }
  }
}
</style>
