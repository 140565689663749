import axios from 'axios';
import { wllib } from './index'
import qs from 'qs';

// 跨域请求是要不要携带cookie
// const axiosOpt = { withCredentials: true };


// 请求头为form形式
export const wlPost = (url, data, showLoading = false, bFailCallBack = false, bJson = false) => {
  if (showLoading) {
    // wllib.global.WLLoading();
  }
  wllib.utils.clearInvalidValue(data)
  if (!bJson) {
    data = qs.stringify(data)
  }
  return new Promise((resolve, reject) => {
    if (bJson) {
      axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
    } else {
      axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    axios.post(url, data).then((res) => {
      var code = res.data.code;
      if (code === 200) {
        resolve(res.data);
      }
      else {
        if (bFailCallBack) {
          resolve(res.data)
        } else {
          wllib.global.WLMessage(res.data.message, "error");
        }
      }
      if (showLoading) {
        // wllib.global.WLLoadingClose()
      }
    }).catch((err) => {
      if (showLoading) {
        // wllib.global.WLLoadingClose()
      }
      wllib.global.WLMessage("网络异常,请重试", "error")
      // wllib.global.WLLoadingClose()
      reject(new Error(err));
    });
  });

};

// 请求头为form形式
export const wlGet = (url, data, showLoading = false, bFailCallBack = false) => {
  if (showLoading) {
    // wllib.global.WLLoading();
  }
  // console.log('url,data===', url, data);
  let stringUrl = wlJson2FormUrl(url, data)
  return new Promise((resolve, reject) => {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.defaults.withCredentials = true

    axios.get(stringUrl).then((res) => {
      if ((!res.data.code && res.data.code !== 0) || res.data.code.toString() === '200') {
        resolve(res.data);
      }
      //登录状态无效或超时
      else if (res.data.code === 514) {
        // wllib.global.WLCommit(WLConst.LOGIN_FLAG, false)
      }
      else {
        if (bFailCallBack) {
          resolve(res.data)
        } else {
          wllib.global.WLMessage(res.data.message, "error");
        }
      }
      if (showLoading) {
        // wllib.global.WLLoadingClose()
      }
    }).catch((err) => {
      if (showLoading) {
        // wllib.global.WLLoadingClose()
      }
      reject(new Error(err));
      // if(!Config.debug){
      //     wllib.global.WLCommit(WLConst.LOGIN_FLAG, false) //临时注释，调试时使用
      // }
    });
  });

};


// 把json 格式转成 query string 格式
export const wlJson2FormUrl = (url, data) => {
  let resUrl = url;
  let arr = [];
  for (var key in data) {
    if (data[key] !== null) {
      arr.push(key + '=' + encodeURIComponent(data[key]));
    }
  }
  if (arr.length > 0) {
    resUrl += '?' + arr.join('&');
  }
  return resUrl;
}