export const regExp = {
    "ip": /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,  //ip
    "mac": /^[A-Fa-f0-9]{2}(-[A-Fa-f0-9]{2}){5}$|^[A-Fa-f0-9]{2}(:[A-Fa-f0-9]{2}){5}$/, //mac地址
    "min": /^(\d+):(\d+):/, //匹配时间；.match [2] 为分，18:30:00 ---> 30
    //合法url
    "url": /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
    "time": /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,  //时间 时:分，匹配00:00至24:00
    "hour": /^(\d+):/,  //匹配以 一个及以上数字开头并带有: 的字符串
    'port': /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/, //端口
    "email": /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,//邮箱
    "second": /^(.*):\d{2}$/, //匹配以 :两个数字 结尾的字符串
    "specail": /[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g, //特殊字符
    "fileName": /[\s*\$*\#*\%*\^*\&*\**\(*\)*\!*\+*\<*\>*\\*\/*\"*\'*\?*\@*]+/, //文件名
    "password": /^[a-zA-Z0-9]{6,18}$/,  //密码，只能由6-18位大小字母或数字组成
    "lowercase": /^[a-z]+$/,  //小写字母
    "uppercase": /^[A-Z]+$/,  //大写字母
    "notFileName": /[^\s*\$*\#*\%*\^*\&*\**\(*\)*\!*\+*\<*\>*\\*\/*\"*\'*\?*\@*]+/, //非文件名
    "upAndlowcase": /^[A-Za-z]+$/,  //大小写字母
    'numNotbeginWith0': /(^[1-9]\d*$)/, //非0开头数字串
  }
  
  export const isvalidUsername = function (str) {
    const valid_map = ['admin', 'editor']
    // return valid_map.indexOf(str.trim()) >= 0
    return (str && str.length >= 1);
  }
  
  
  export const validateTest = function(regExpType,val){
    return regExp[regExpType].test(val)
  }
  
  export const validateExec = function(regExpType,val){
    return regExp[regExpType].exec(val)
  }