import * as net from './net';
import * as validate from './validate';
import * as utils from './utils';
import * as global from './global';

export const wllib = {
    net,
    validate,
    utils,
    global
}