// 框架用的通用方法
import { wllib } from './index'

// 清除空值
export function clearInvalidValue(query) {
  if (query) {
    Object.keys(query).forEach(key => {
      // 删除 undefined null [] ''
      if (query[key] == null || query[key].length === 0) {
        delete query[key]
      }
    })
  }
}

export function wlIsNotNull(param) {
  return !wlIsNull(param) && !wlIsUndefined(param);
}

export function wlIsNull(param) {
  return Object.prototype.toString.call(param) === '[object Null]';
}

export function wlIsUndefined(param) {
  return Object.prototype.toString.call(param) === '[object Undefined]';
}

//获取时间 yyyy年mm月dd日
export function dateFormat(date, type = 'ch') {
  let d = new Date(date);
  let res
  if (type === 'ch')
    res = d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日'
  else
    res = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
  return res
}

export function getRealPicUrl(url){
  if(url.includes('http'))
    return url
  return Config.ajaxUrl + url
}

export function html2text(html) {
  const el = document.createElement('div');
  el.id = 'panel';
  el.innerHTML = html
  var inputs = el.getElementsByTagName("input");
  for(var i=0;i<inputs.length;i++){
      inputs[i].parentNode.innerHTML = inputs[i].value;
  }
  return el.innerText
}