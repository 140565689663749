<template>
  <section>
    <h2>{{title}}</h2>
    <strong v-if="isNeedDesc" :style="{paddingTop:descPt,paddingBottom:descPb}">{{desc}}</strong>
  </section>
</template>

<script>
export default {
  name: 'wlTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    isNeedDesc: {
      type: Boolean,
      default: true
    },
    desc: {
      type: String,
      default: ''
    },
    descPt: {
      type: String,
      default: ''
    },
    descPb: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  color: #262626;
  margin: 0;
}
strong {
  color: #3b3b3b;
  font-size: 20px;
  padding-top: 26px;
  padding-bottom: 65px;
}
</style>
