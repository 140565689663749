<template>
  <svg disabled :class="svgClass" aria-hidden="true" @click="doClick">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
    name: "svgIcon",
    props: {
        iconClass: {
            default:'c_tj',
            type: String,
            required: true
        },
        className: {
            type: String
        },
        clickable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        iconName() {
            return `#icon-${this.iconClass}`;
        },
        svgClass() {
            if (this.className) {
                return "svg-icon " + this.className;
            } else {
                return "svg-icon";
            }
        }
    },
    methods: {
        doClick() {
            if (this.clickable) {
                this.$emit("click");
            }
        }
    }
};
</script>