<template>
  <section class="page bg" v-lazy:background-image="'/static/img/product/labManagePlatform/bg-10S.png'">
    <article class="container">
      <h3>10秒提交需求，给您提供合适解决方案</h3>
      <section class="flexWrap">
        <section class="flexWrap left">
          <el-input placeholder="请输入您的单位名称" v-model="submitInfo.company"></el-input>
          <el-input placeholder="请输入您的城市" v-model="submitInfo.city"></el-input>
          <el-input placeholder="请输入您的姓名" v-model="submitInfo.name"></el-input>
          <el-input placeholder="请输入您的手机号码" v-model="submitInfo.mobile"></el-input>
        </section>
        <section class="needBox">
          <textarea class="need" placeholder="请输入您的需求信息" v-model="submitInfo.content"></textarea>
        </section>
      </section>
      <button class="primary" @click="submit">确认提交</button>
    </article>
  </section>
</template>

<script>
import { wllib } from '../utils/index'
export default {
  name: 'submitPage',
  props: {
  },
  data() {
    return {
      submitInfo: {},
    }
  },
  methods: {
    submit() {
      if (!this.submitInfo.name || !this.submitInfo.mobile || !this.submitInfo.city || !this.submitInfo.company || !this.submitInfo.content) {
        wllib.global.WLMessage('请填写完整信息后再提交~', "warning");
        return
      }
      if (!this.checkMobile(this.submitInfo.mobile))
        return
      wllib.net.wlPost(Config.ajaxUrl + "/requirement/save", this.submitInfo, false, true).then((res) => {
        if (res && res.code == 200) {
          wllib.global.WLMessage('提交成功~', "success");
          setTimeout(() => {
            this.submitInfo = {}
          }, 800);
        }
      })
    },
    checkMobile(value) {
      const regMobile = /^1[0-9]{10}$/
      if (!regMobile.test(value)) {
        // 不合法的手机号码
        wllib.global.WLMessage('手机号码格式不正确，请重新输入~', "warning");
        return false
      }
      return true
    }
  }
}
</script>

<style lang='scss' scoped>
.page {
  height: 567px;
  padding-top: 110px;
  text-align: center !important;
  .container {
    width: 1047px;
    h3 {
      font-size: 32px !important;
      color: #303030;
      padding-bottom: 65px;
      line-height: 40px;
      font-weight: bold;
    }
    .left {
      width: 656px;
    }
  }
  .need {
    width: 377px;
    height: 128px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #aaaaaa;
    background: #fff;
    font-size: 20px;
    color: #8e8e8e;
    padding: 16px;
    padding-left: 30px;
    padding-top: 12px;
    font-family: Noto Sans S Chinese-Regular, Noto Sans S Chinese;
  }
  .primary {
    width: 281px;
    height: 52px;
    background: #308dff;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 41px;
    &:hover {
      opacity: 0.9;
    }
  }
}
::v-deep.el-input {
  width: 321px;
  padding-bottom: 24px;
  position: relative;
  &::before {
    content: "*";
    color: red;
    position: absolute;
    left: 16px;
    top: 16px;
    display: block;
    width: 4px;
    height: 40px;
    font-size: 20px;
  }
}
::v-deep .el-input--mini .el-input__inner {
  height: 52px;
  line-height: 52px;
  font-size: 20px;
  border: 1px solid #aaaaaa;
  padding-left: 30px;
  color: #8e8e8e;
}
::v-deep .el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #aaaaaa;
  outline: 0;
}
::-webkit-input-placeholder {
  color: #b2b2b2;
}
</style>
