import Vue from 'vue'
import App from './App.vue'
import router from './router'
import registerComponents from './components/index.js'
import './icons'
import '../static/config/config.js'
import VueLazyload from 'vue-lazyload'
import loadingImg from '@/assets/default.png';
import { Carousel, CarouselItem, Input, Icon, Pagination } from 'element-ui'

Vue.config.productionTip = false

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadingImg,
  attempt: 1
})

Vue.prototype.$ELEMENT = { size: 'mini', zIndex: 3000 };
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(Input);
Vue.use(Icon);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: { App },
  // 预渲染
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
})

registerComponents(Vue) // 注册自定义全局组件
